import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath, router } from '../router';

import { signOut } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import 'https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.12.0/cdn/components/animation/animation.js';
import SlDrawer from '@shoelace-style/shoelace/dist/components/drawer/drawer.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import  '../shared-functions';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../graphql/queries';

@customElement('app-footer')
export class AppFooter extends LitElement {
  @property({ type: String }) title = 'letssea.me';
  @property({type: Boolean}) menuOpen: boolean = false;
  @property({ type: Boolean}) enableBack: boolean = false;
  @property({ type: Boolean}) loggedIn = false;

  constructor(){
    super();
    this.currentAuthenticatedUser();
    this.menuOpen = false;
    this.loggedIn = false;

  }

  async firstUpdated() {
    // Give the browser a chance to paint
    await new Promise((r) => setTimeout(r, 1));
    await new Promise(resolve => setTimeout(resolve, 1000));
    const drawer = this.shadowRoot?.querySelector('.drawer-placement-start') as SlDrawer
    const openButton = drawer!.nextElementSibling; //TODO drawer is sometimes null
    const closeButton = this.shadowRoot!.querySelector('sl-button[variant="primary"]');
    openButton!.addEventListener('click', () => drawer.show());
    closeButton!.addEventListener('click', () => drawer.hide());
  }
  static styles = css`
    footer {
      z-index: 2000;
      width:100%;
      display: flex;
      flex-direction:row;
      justify-content: space-around;
      align-items: center;
     /* background: var(--app-color-primary);*/
     background: 	white;
      color: white;
      height: 4em;
      padding-left: 5px;
      padding-top: 0px;


      position: fixed;
      left: env(titlebar-area-x, 0);

      top: calc(100vh - 60px);


      height: env(titlebar-area-height, 60px);

      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;
    }
    .drawer-placement-start{
      z-index:2000;
    }

    footer h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: bold;
    }

    footer p {
      color: black;
    }
    nav a {
      margin-left: 10px;
    }

    #menu {
      margin-right: 10px;
    }
    #back-button-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 12em;
    }

    .nav-brand{
      margin-right:5px;
    }
    @media(prefers-color-scheme: light) {
      footer {
       /* color: black;*/
      }

      nav a {
        color: initial;
      }
    }

    sl-button::part(base){
      background-color:#1E2952;
      border-color:black;

    }
    sl-button::part(label){

      color:white;
    }

    sl-badge::part(base){
      background-color:#1E2952;
      width: 35px;
      height: 35px;
      color: white;
    }

  `;

  render() {


    return this.loggedIn ? html`


      <footer>
      <p>Privacy Policy</p>
      <p>Terms and Conditions</p>
      <p>Cookie Policy</p>
      </footer>
    `: html ``;
  }


    //if this method throws an error if the user is not logged in or signed up.
    async currentAuthenticatedUser() {
      try {
        const { username, userId, signInDetails } = await getCurrentUser();
        console.log(`The username: ${username}`);
        console.log(`The userId: ${userId}`);
        console.log(`The signInDetails: ${signInDetails}`);
        this.loggedIn = true;
        let userEmailAddress = localStorage.getItem("emailAddress") as string;
        this.fetchUserProfileInfo(userEmailAddress);
      } catch (err) {
          console.log(err);


      }
    }

    async fetchUserProfileInfo(emailAddress:string){

     const client = generateClient();
     const variables = {
       filter:{
         emailAddress:{
           eq: emailAddress
         }
       }

     };
     const result = await client.graphql({
          query: queries.listUserProfiles,
          variables: variables
     });
     console.log(result);
      let responseDynmoDB_ID = (result.data.listUserProfiles!.items![0]?.id);
     console.log(responseDynmoDB_ID);
     // setUserType(result.data.listUserProfiles!.items![0]?.userType?.toString()!);



      globalThis.incomingSwimmers = 0;
      const incomingSwimmersDataFromAPI = result.data.listUserProfiles!.items![0]?.incomingSwimmers?.length;
      globalThis.incomingSwimmers = Number(incomingSwimmersDataFromAPI!);
      globalThis.globalCurrentUserID = result.data.listUserProfiles!.items![0]?.id as string;
      globalThis.globalUserType = result.data.listUserProfiles!.items![0]?.userType as string;
     }


  async handleSignOut() {
    try {
      await signOut();
      console.log("signing out");
    } catch (error) {
      console.log('error signing out: ', error);
    }
    const drawer = this.shadowRoot?.querySelector('.drawer-placement-start') as SlDrawer
    drawer?.hide();
    const routePath=resolveRouterPath('login');
    router.navigate(routePath);

  }


}
