// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md
//
import { html } from 'lit';



if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';
import { scrollToTop } from '@thepassle/app-tools/router/plugins/scrollToTop.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app-home.js';
import './pages/app-activities/app-activities.js'
//import { data } from '@thepassle/app-tools/router/plugins/data.js';
import { getCurrentUser } from 'aws-amplify/auth';
const baseURL: string = (import.meta as any).env.BASE_URL;
var databaseID:String = 'fromrouter';
var profileUrl:String = 'fromrouter';
export const buildNumber = 'Build 0.0.392';
export const PROFILE_PIC_MAX_SIZE = .4;
export const BIO_PIC_MAX_SIZE = .3;
export var gActivityNames = ['']; //ist of activities
export const g_emailAddress = '';
export var g_userType:string='';
var gNumberOfIncomingSwimmers:string;




export const router = new Router({
  /** Plugins to be run for every route */
  plugins: [
    scrollToTop,
  {
    name: "IsUserAWSAutenticatedPlugin",
      shouldNavigate: () => ({

         condition: () => isUserLoggedIn,
         redirect: 'login'
      })
  }
  ],

 fallback: resolveRouterPath(),

    routes: [

      {
        path: resolveRouterPath('login'),
        title: 'login',
        plugins: [
          lazy(() => import('./pages/app-login/app-login.js')),
        ],
        render: () => html`<app-login ?databaseID=${databaseID}></app-login>`
      },


      {
        path: resolveRouterPath('oldhome'),
        title: 'Home',
        render: () => html`<app-home></app-home>`
      },
      {
        path: resolveRouterPath('grid'),
        title: 'Grid',
        plugins: [
          lazy(() => import('./pages/app-grid/app-grid.js')),

        ],
        render: () => html`<app-grid></app-grid>`
      },

      {
        path: resolveRouterPath(''),
        title: 'Home',
        plugins: [
          lazy(() => import('./pages/app-about/app-about.js')),
        ],
        render: () => html`<app-about></app-about>`
      },

      //was 'activities'
      {
        path: resolveRouterPath('dateideas'),
        title: 'Activities',
        plugins: [
        ],
        render: () => html`<app-activities></app-activities>`
      },
      {
        path: resolveRouterPath('dates'),
        title: 'Dates',
        plugins: [
          lazy(() => import('./pages/app-datesuggestions/app-datesuggestions.js')),
        ],
        render: () => html`<app-datesuggestions></app-datesuggestions>`
      },
      {
        path: resolveRouterPath('predates'),
        title: 'PreDates',
        plugins: [
          lazy(() => import('./pages/app-predate/app-predate.js')),
        ],
        render: () => html`<app-predate></app-predate>`
      },

      {
        path: resolveRouterPath('preferences'),
        title: 'Preferences',
        plugins: [
          lazy(() => import('./pages/app-preferences/app-preferences.js')),
        ],
        render: () => html`<app-preferences></app-preferences>`
      },
      {
        path: resolveRouterPath('profile'),
        title: 'Profile',
        plugins: [
          lazy(() => import('./pages/app-profile/app-profile.js')),
        ],
        render: () => html`<app-profile></app-profile>`
      },
      /*
      {
        path: resolveRouterPath('about'),
        title: 'About',
        plugins: [
          lazy(() => import('./pages/app-about/app-about.js')),
        ],
        render: () => html`<app-about></app-about>`
      },
      */
      {
        path: resolveRouterPath('biography'),
        title: 'Biography',
        plugins: [
          lazy(() => import('./pages/app-biography/app-biography.js')),
        ],
        render: () => html`<app-biography></app-biography>`
      },
      {
        path: resolveRouterPath('results'),
        title: 'Results',
        plugins: [
          lazy(() => import('./pages/app-results/app-results.js')),
        ],
        render: () => html`<app-results></app-results>`
      },
      {

        path: resolveRouterPath('resultDetails'),
        title: 'ResultDetails',
        plugins: [
          lazy(() => import('./pages/app-resultdetails/app-resultdetails.js')),
        ],
        render: () => html`<app-resultDetails ?databaseID=${databaseID}></app-resultDetails>`
       // render: () => html`<app-resultDetails ?databaseID="123123"></app-resultDetails>`
      },
      {
        path: resolveRouterPath('notifications'),
        title: 'Notifications',
        plugins: [
          lazy(() => import('./pages/app-notifications/app-notifications.js')),
        ],
        render: () => html`<app-notifications></app-notifications>`
      },
      {
      path: resolveRouterPath('chat'),
      title: 'chat',
      plugins: [
        lazy(() => import('./pages/app-chat/app-chat.js')),
      ],
      render: () => html`<app-chat ?databaseID=${databaseID}></app-chat>`
      },
      {
        path: resolveRouterPath('mychats'),
        title: 'mychats',
        plugins: [
          lazy(() => import('./pages/app-mychats/app-mychats.js')),
        ],
        render: () => html`<app-mychats ?databaseID=${databaseID}></app-mychats>`
      }
      /*
      {
        path: resolveRouterPath('login'),
        title: 'login',
        plugins: [
          lazy(() => import('./pages/app-login/app-login.js')),
        ],
        render: () => html`<app-login ?databaseID=${databaseID}></app-login>`
      }
      */


    ]
  });


  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.

  export function resolveRouterPath(unresolvedPath?: string) {
    //TODO add in a check that prevents leaving the profile page if the profile is not filled out
    //const pathname = window.location.pathname;


    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }
    //console.log("resolveRouterPath",resolvedPath);
    return resolvedPath;
  }

  //this is the database ID of the matched person that the user is currenty looking at
  export function setDatabaseID(dbid:string){
   databaseID = dbid;
   console.log("router-- dbid=",databaseID);
   localStorage.setItem("currentDatabaseID",  dbid);
  }
  //this is the profile image of the matched person that the user is currently looking at
  export function setProfileImage(url:string){
    profileUrl=url;
    console.log(profileUrl)
    localStorage.setItem("currentProfileUrl",url)
  }
  //this is the screenname of the matched person that the user is currently looking at
  export function setUserName(username:string){
    localStorage.setItem("username",username)
  }

  export function setActivityFilter(filter:string){
    localStorage.setItem('activityFilter', filter);
  }
  export function setActivityNames(activityNames:string[]){
    gActivityNames = activityNames
  }


  export function getNumberOfIncomingSwimmers(){
    return gNumberOfIncomingSwimmers
  }
  export function setUserType(userType:string){
    g_userType = userType;
  }
/*
  async function isUserLoggedIn():Promise<boolean> => {

      await getCurrentUser();
      if (globalThis.globalIsUserLoggedIn){
        return new Promise((resolve) => { resolve(true); })
      }
      else {
        return new Promise((resolve) => { resolve(false); })
      }
  }
  */
  const isUserLoggedIn = (): Promise<boolean> => {
    console.log("router check");
    return new Promise(async (resolve) => {
        // Simulate a delay (e.g., fetching data or checking a condition)
            console.log("router: checking if user is logged in");
            await getCurrentUser();
           // resolve(globalThis.globalIsUserLoggedIn);
           resolve(false);

    });
};