/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserProfiles = /* GraphQL */ `mutation CreateUserProfiles($input: CreateUserProfilesInput!) {
  createUserProfiles(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProfilesMutationVariables,
  APITypes.CreateUserProfilesMutation
>;
export const updateUserProfiles = /* GraphQL */ `mutation UpdateUserProfiles($input: UpdateUserProfilesInput!) {
  updateUserProfiles(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfilesMutationVariables,
  APITypes.UpdateUserProfilesMutation
>;
export const deleteUserProfiles = /* GraphQL */ `mutation DeleteUserProfiles($input: DeleteUserProfilesInput!) {
  deleteUserProfiles(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProfilesMutationVariables,
  APITypes.DeleteUserProfilesMutation
>;
export const addMatch = /* GraphQL */ `mutation AddMatch($input: AddMatchInput!) {
  addMatch(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddMatchMutationVariables,
  APITypes.AddMatchMutation
>;
export const removeOutGoingLikes = /* GraphQL */ `mutation RemoveOutGoingLikes($input: OutGoingLikesInput!) {
  removeOutGoingLikes(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveOutGoingLikesMutationVariables,
  APITypes.RemoveOutGoingLikesMutation
>;
export const addOutGoingLikes = /* GraphQL */ `mutation AddOutGoingLikes($input: OutGoingLikesInput!) {
  addOutGoingLikes(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOutGoingLikesMutationVariables,
  APITypes.AddOutGoingLikesMutation
>;
export const addIncomingLikes = /* GraphQL */ `mutation AddIncomingLikes($input: IncomingLikesInput!) {
  addIncomingLikes(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddIncomingLikesMutationVariables,
  APITypes.AddIncomingLikesMutation
>;
export const removeIncomingLikes = /* GraphQL */ `mutation RemoveIncomingLikes($input: IncomingLikesInput!) {
  removeIncomingLikes(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveIncomingLikesMutationVariables,
  APITypes.RemoveIncomingLikesMutation
>;
export const addIncomingSwimmers = /* GraphQL */ `mutation AddIncomingSwimmers($input: AddIncomingSwimmersInput!) {
  addIncomingSwimmers(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddIncomingSwimmersMutationVariables,
  APITypes.AddIncomingSwimmersMutation
>;
export const addBiographyPicture = /* GraphQL */ `mutation AddBiographyPicture($input: AddBiographyPictureInput!) {
  addBiographyPicture(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddBiographyPictureMutationVariables,
  APITypes.AddBiographyPictureMutation
>;
export const addProfilePictureName = /* GraphQL */ `mutation AddProfilePictureName($input: AddProfilePictureNameInput!) {
  addProfilePictureName(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddProfilePictureNameMutationVariables,
  APITypes.AddProfilePictureNameMutation
>;
export const addImageActivtyPreference = /* GraphQL */ `mutation AddImageActivtyPreference($input: AddImageActivtyPreferenceInput!) {
  addImageActivtyPreference(input: $input) {
    emailAddress
    userFirstName
    userMiddleName
    userLastName
    userScreenName
    heightFeet
    heightInches
    age
    gender
    targetGender
    zipCode
    phoneNumber
    profilePicture
    biography
    biographyPictures
    profilePictureNames
    preferences
    personalInfo
    locationRadius
    minAge
    maxAge
    userType
    resultsYes
    resultsNo
    resultsMaybe
    userMatches
    incomingSwimmers
    id
    profileComplete
    imageActivityPreference
    outgoingLikes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddImageActivtyPreferenceMutationVariables,
  APITypes.AddImageActivtyPreferenceMutation
>;
export const createLSMActivitiesTable = /* GraphQL */ `mutation CreateLSMActivitiesTable($input: CreateLSMActivitiesTableInput!) {
  createLSMActivitiesTable(input: $input) {
    id
    activityIndex
    activityName
    activityCategory
    activityDescription
    activityEnvironment
    activityLabel
    activityTitle
    activityType
    activityUrl1
    activityUrl2
    activityUrl3
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLSMActivitiesTableMutationVariables,
  APITypes.CreateLSMActivitiesTableMutation
>;
export const updateLSMActivitiesTable = /* GraphQL */ `mutation UpdateLSMActivitiesTable($input: UpdateLSMActivitiesTableInput!) {
  updateLSMActivitiesTable(input: $input) {
    id
    activityIndex
    activityName
    activityCategory
    activityDescription
    activityEnvironment
    activityLabel
    activityTitle
    activityType
    activityUrl1
    activityUrl2
    activityUrl3
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLSMActivitiesTableMutationVariables,
  APITypes.UpdateLSMActivitiesTableMutation
>;
export const deleteLSMActivitiesTable = /* GraphQL */ `mutation DeleteLSMActivitiesTable($input: DeleteLSMActivitiesTableInput!) {
  deleteLSMActivitiesTable(input: $input) {
    id
    activityIndex
    activityName
    activityCategory
    activityDescription
    activityEnvironment
    activityLabel
    activityTitle
    activityType
    activityUrl1
    activityUrl2
    activityUrl3
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLSMActivitiesTableMutationVariables,
  APITypes.DeleteLSMActivitiesTableMutation
>;
